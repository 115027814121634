import { useAbort, useApi } from "@/use/api/useApi";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import type { Ref } from "vue";
import type { FilteredEntity } from "@/utils/getters/defaultRequestInfo";

function preRequestMeta(state: Ref<FilteredEntity>, cancel: Function) {
  if (state.value.info.request) state.value.info.request.cancel();

  state.value.info.loading = true;
  state.value.info.error = false;
  state.value.info.request = { cancel: cancel }
}

function afterRequestMeta(state: Ref<FilteredEntity>, error = false) {
  state.value.info.loading = false;
  state.value.info.error = error;
  state.value.info.request = null;
}

function getAnalyticIndustryFunction(url: string) {
  switch (url) {
    case 'monthly': return useApi().analytics.industry.fetchIndustryMonthlyChart;
    case 'regions': return useApi().analytics.industry.fetchIndustryRegionsChart;

    case 'lots': return useApi().analytics.industry.fetchILotsList;
    case 'customers': return useApi().analytics.industry.fetchICustomersList;
    case 'suppliers': return useApi().analytics.industry.fetchISuppliersList;

    default: return useApi().analytics.industry.fetchIndustryCommon;
  }
}

function getAnalyticSuppliersFunction(url: string) {
  switch (url) {
    case 'monthly': return useApi().analytics.suppliers.fetchSuppliersMonthlyChart;
    case 'regions': return useApi().analytics.suppliers.fetchSuppliersRegionsChart;

    case 'won': return useApi().analytics.suppliers.fetchSWonList;
    case 'participated': return useApi().analytics.suppliers.fetchSParticipatedList;
    case 'customers': return useApi().analytics.suppliers.fetchSCustomersList;
    case 'competitors': return useApi().analytics.suppliers.fetchSCompetitorsList;

    default: return useApi().analytics.suppliers.fetchSuppliersCommon;
  }
}

function getAnalyticCustomersFunction(url: string) {
  switch (url) {
    case 'monthly': return useApi().analytics.customers.fetchCustomersMonthlyChart;
    case 'regions': return useApi().analytics.customers.fetchCustomersRegionsChart;

    case 'actual': return useApi().analytics.customers.fetchCActualList;
    case 'completed': return useApi().analytics.customers.fetchCCompletedList;
    case 'suppliers': return useApi().analytics.customers.fetchCSuppliersList;
    case 'participants': return useApi().analytics.customers.fetchCParticipantsList;

    default: return useApi().analytics.customers.fetchCustomersCommon;
  }
}

function getEntityFunction(tab: 'industry'|'suppliers'|'customers', url: string) : (payload: any, signal: AbortSignal, offset?: number) => Promise<unknown> {
  if (tab === 'industry') return getAnalyticIndustryFunction(url);
  if (tab === 'suppliers') return getAnalyticSuppliersFunction(url);
  return getAnalyticCustomersFunction(url);
}

function getCommon(meta: Ref<FilteredEntity>, tab: 'industry'|'suppliers'|'customers', url: 'common'|'monthly'|'regions', payload?: any) {
  const { signal, abort, } = useAbort();

  preRequestMeta(meta, abort)

  const commonFunction = getEntityFunction(tab, url);
  if (!commonFunction) return;

  commonFunction(payload, signal)
    .then((data) => {
      if (data) meta.value.data = data
      afterRequestMeta(meta)
    })
    .catch((error) => {
      if (!signal.aborted) afterRequestMeta(meta, true)
    })
}

export function resetRequestMeta(meta: Ref<FilteredEntity>, data : any = null) {
  if (meta.value.info.request) meta.value.info.request.cancel();
  meta.value.data = data;
  meta.value.info = getDefaultRequestCommonInfo();
}

export function getCommonCards(meta: Ref<FilteredEntity>, tab: 'industry'|'suppliers'|'customers', payload?: any) {
  getCommon(meta, tab, 'common', payload)
}

export function getChart(meta: Ref<FilteredEntity>, tab: "suppliers" | "customers" | "industry", url: "monthly" | "regions", payload?: any) {
  getCommon(meta, tab, url, payload)
}

export function getEntity(entitiesLists: Ref<any>, entitiesListsInfo: Ref<any>, mode: string, path: string, tab: 'industry'|'suppliers'|'customers', payload: any) {
  if (!entitiesListsInfo.value[mode].meta.isOver) {
    const { signal, abort } = useAbort();

    if (entitiesListsInfo.value[mode].meta.request) {
      entitiesListsInfo.value[mode].meta.request.cancel();
    }

    entitiesListsInfo.value[mode].meta.loading = true
    entitiesListsInfo.value[mode].meta.error = false
    entitiesListsInfo.value[mode].meta.request = { cancel: abort };

    const entityFunction = getEntityFunction(tab, path);
    if (!entityFunction) return;

    entityFunction(payload, signal, entitiesListsInfo.value[mode].meta.part)
      .then((response) => {
        const data = response || []

        if (data.length) {
          entitiesLists.value[mode].push(...data);
          entitiesListsInfo.value[mode].options.info.count += data.length;
          entitiesListsInfo.value[mode].meta.part++;
        } else entitiesListsInfo.value[mode].meta.isOver = true;
      })
      .catch((error) => {
        if (!signal.aborted) entitiesListsInfo.value[mode].meta.error = true;
      })
      .finally(() => {
        if (!signal.aborted) {
          entitiesListsInfo.value[mode].meta.loading = false;
          entitiesListsInfo.value[mode].meta.request = null;
        }
      })
  }
}
